import { createTheme, Theme, ThemeOptions } from "@mui/material";
import CheckboxIcon from "../components/utils/Checkbox";
import { colors } from "./colors";

export const themeConfig: ThemeOptions = {
  palette: {
    primary: {
      main: colors.green
    },
    secondary: {
      main: colors.grey
    }
  },
  typography: {
    fontFamily: "Poppins, sans-serif"
  },
  spacing: 5,
  components: {}
};

const theme = createTheme(themeConfig);

const defaultTheme: Theme = {
  ...theme,
  typography: {
    ...theme.typography,
    h1: {
      fontSize: "2.7rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "2.3rem"
      }
    },
    h2: {
      fontWeight: 600,
      fontSize: "2.5rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "2.1rem"
      }
    },
    h4: {
      fontSize: "1.8rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.5rem"
      }
    },
    h5: {
      fontSize: "1.2rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.1rem"
      }
    },
    body2: {
      fontWeight: 400,
      lineHeight: 2,
      fontSize: "1.1rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.0rem"
      }
    },
    body1: {
      fontWeight: 400,
      lineHeight: 2,
      fontSize: "0.8rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.7rem"
      }
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.5
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.capuccino,
          borderRadius: 30
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "white",
          padding: 0
        },
        root: {
          "& .Mui-selected": {
            backgroundColor: colors.capuccino
          }
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
          padding: "1.5rem"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: "50px"
        },
        notchedOutline: {
          borderWidth: "3px !important",
          borderRadius: 12,
          borderColor: colors.brown
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxIcon checked={true} />
      },
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fontSize: "2.5rem"
          },
          marginRight: "1rem"
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          color: colors.green,
          backgroundColor: colors.green
        },
        rail: {
          backgroundColor: colors.green
        },
        mark: {
          backgroundColor: colors.darkGreen,
          height: "0.6rem"
        },
        valueLabel: {
          fontSize: "1rem"
        },
        thumb: {
          width: 20,
          height: 20,
          borderRadius: 5,
          backgroundColor: colors.green
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "1.0rem",
          [theme.breakpoints.down("md")]: {
            fontSize: "0.8rem"
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 15
        },
        action: {
          padding: 4,
          paddingLeft: 10
        },
        icon: {
          fontSize: "2rem",
          paddingRight: 10,
          "@media (min-width: 900px)": {
            display: "flex",
            alignItems: "center"
          }
        }
      }
    }
  }
};

export default defaultTheme;
