/* tslint:disable */
/* eslint-disable */
/**
 * EcoCerram API
 * EcoCerram API generated from BE
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  OrderItemDto,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OrderItemDtoFromJSON,
    OrderItemDtoToJSON,
} from '../models';

export interface CreateOrderRequest {
    orderItemDto: Array<OrderItemDto>;
}

/**
 * 
 */
export class OrderControllerApi extends runtime.BaseAPI {

    /**
     */
    async createOrderRaw(requestParameters: CreateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderItemDto === null || requestParameters.orderItemDto === undefined) {
            throw new runtime.RequiredError('orderItemDto','Required parameter requestParameters.orderItemDto was null or undefined when calling createOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.orderItemDto.map(OrderItemDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createOrder(requestParameters: CreateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrderRaw(requestParameters, initOverrides);
    }

}
