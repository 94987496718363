import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
  useTheme
} from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorResponse } from "../api";
import Button, { ButtonSize, ButtonVariant } from "../components/utils/Button";
import ButtonContainer from "../components/utils/ButtonContainer";
import NavButton from "../components/utils/NavButton";
import RenderContent from "../components/utils/RenderContent";
import CartItem from "../components/view/CartItem";
import { routes } from "../constants/routes";
import { CartContext } from "../context/CartContext";
import { ContentfulContext } from "../context/ContentfulContext";
import { ErrorContext } from "../context/ErrorContext";
import { ProductsContext } from "../context/ProductsContext";
import { UserContext } from "../context/UserContext";
import { Asset, Cart as CartModel, Language } from "../models/schema";
import { orderApi } from "../querries/api";
import { getButton, getValidationMsg } from "../querries/getters";
import { formatPrice, getPriceWithTax } from "../utils/utilities";
import OperationResult, { OperationResultType } from "./auth/OperationResult";

const Cart = () => {
  const { user } = useContext(UserContext);
  const { content } = useContext(ContentfulContext);
  const { products } = useContext(ProductsContext);

  const {
    title,
    empty,
    emptyLoggedIn,
    totalSum,
    totalSumWithTax,
    withTax,
    withoutTax,
    consentTerms,
    consentTermsEnabled,
    successMessage,
    errorMessage
  } = content?.cart as CartModel;
  const { validationErrorMessage } = content as Language;

  const buttons = content?.buttonsCollection?.items;
  const validations = content?.validationsCollection?.items;

  const { cart, resetCart } = useContext(CartContext);
  const { setError } = useContext(ErrorContext);

  const [result, setResult] = useState<OperationResultType>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>();

  const theme = useTheme();

  const confirmOrder = () => {
    setLoading(true);

    orderApi
      .createOrder({
        orderItemDto: cart
      })
      .then(() => {
        setResult("SUCCESS");
        setLoading(false);
        resetCart();
      })
      .catch((error) => {
        setLoading(false);

        if (error?.response?.status === 401) {
          setResult("ERROR");
          return;
        }

        error.response.json().then((json: ErrorResponse) => {
          console.error(json);

          if (json?.errorCode) {
            setErrorCode(json?.errorCode);
          }

          // Bussiness logic errors are 4xx
          if (Math.floor(error?.response?.status / 100) === 4) {
            setResult("ERROR");
            return;
          }

          // Otherwise it's an unexpected server error
          setError(error);
        });
      });
  };

  const total = useMemo(
    () =>
      products?.length > 0
        ? cart?.reduce((acc, item) => {
            const product = products?.find((p) => p.ean === item.ean);

            if (!product || (!product.priceForCurrentUser && !product.price)) {
              return acc;
            }

            const pricePerPackage = product?.pricePerPackage ?? 0;
            const totalSum = (item.quantity ?? 0) * pricePerPackage;

            return acc + totalSum;
          }, 0)
        : 0,
    [products, cart]
  );
  const totalWithTax = useMemo(() => total && getPriceWithTax(total), [total]);

  type Form = {
    consentTerms: string;
  };

  const controls: Form = {
    consentTerms: "consentTerms"
  };

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography variant="h1" fontWeight="bold" textAlign="center" mb={{ xs: 4, md: 8 }}>
        {title}
      </Typography>

      {!result && (!cart || cart?.length === 0) && (
        <>
          <RenderContent
            content={!user ? empty?.json : emptyLoggedIn?.json}
            sx={{ mb: 8, textAlign: "center" }}
          />
          <ButtonContainer>
            {!user && (
              <NavButton
                route={routes.login}
                label={getButton(buttons, "login")}
                variant={ButtonVariant.primary}
                size={ButtonSize.full}
                sx={{
                  width: theme.spacing(40)
                }}
              />
            )}

            <NavButton
              route={routes.products}
              label={getButton(buttons, "products")}
              variant={!user ? ButtonVariant.secondary : ButtonVariant.primary}
              size={ButtonSize.full}
              sx={{
                width: theme.spacing(40)
              }}
            />
          </ButtonContainer>
        </>
      )}

      {cart?.length > 0 && !result && (
        <>
          {cart?.map((item) => (
            <CartItem key={item.ean} ean={item.ean} />
          ))}

          <Divider sx={{ width: "100%", mb: 5 }} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mb: 2
            }}>
            <Typography variant="h4">{totalSum}:</Typography>
            <Typography variant="h4" fontWeight="bold">
              {formatPrice(total)} <Box display={{ xs: "none", md: "inline" }}>({withoutTax})</Box>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mb: 5
            }}>
            <Typography variant="h5" fontWeight={100}>
              {totalSumWithTax}:
            </Typography>
            <Typography variant="h5" fontWeight={100}>
              {formatPrice(totalWithTax)}{" "}
              <Box display={{ xs: "none", md: "inline" }}>({withTax})</Box>
            </Typography>
          </Box>

          {consentTermsEnabled && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                width: "100%",
                mb: 5
              }}>
              <Controller
                name={controls.consentTerms}
                rules={{
                  required: getValidationMsg(validations, "required")
                }}
                control={control}
                render={({ field }) => (
                  <FormControl
                    required
                    sx={(theme) => ({
                      width: { xs: "100%", md: "40rem" },
                      color: errors.consentTerms
                        ? theme.palette.error.main
                        : theme.palette.text.primary
                    })}
                    error={!!errors.consentTerms}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={(theme) => ({
                            color: errors.consentTerms
                              ? theme.palette.error.main
                              : theme.palette.text.primary
                          })}
                          {...field}
                        />
                      }
                      label={
                        <RenderContent
                          content={consentTerms?.json}
                          link={consentTerms?.links?.assets?.hyperlink[0] as Asset}
                        />
                      }
                      color={errors.consentTerms ? "error" : "primary"}
                    />
                    <FormHelperText color="error">
                      {errors.consentTerms && (errors.consentTerms.message as string)}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Box>
          )}

          <Button
            loading={loading}
            onClick={handleSubmit(confirmOrder)}
            label={getButton(buttons, "placeOrder")}
            variant={ButtonVariant.primary}
            size={ButtonSize.full}
            sx={{
              width: "20rem"
            }}
          />
        </>
      )}

      {result && (
        <Box mb={5}>
          <OperationResult
            result={result}
            successTitle={successMessage as string}
            errorTitle={
              errorCode
                ? `${validationErrorMessage} ${getValidationMsg(validations, errorCode)}`
                : (errorMessage as string)
            }>
            {result === "ERROR" && (
              <ButtonContainer>
                <NavButton
                  route={routes.contact}
                  label={getButton(buttons, "contact")}
                  variant={ButtonVariant.secondary}
                />

                <Button
                  onClick={() => setResult(null)}
                  label={getButton(buttons, "tryAgain")}
                  variant={ButtonVariant.primary}
                />
              </ButtonContainer>
            )}
          </OperationResult>
        </Box>
      )}
    </Box>
  );
};

export default Cart;
