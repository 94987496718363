import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Alert, Box, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { StockEditionsEnum } from "../api";
import Button, { ButtonVariant } from "../components/utils/Button";
import LoadingSpinner from "../components/utils/LoadingSpinner";
import RenderContent from "../components/utils/RenderContent";
import BecomePartnerBanner from "../components/view/BecomePartnerBanner";
import ProductCard from "../components/view/ProductCard";
import ProductsFilter, { FilterParams } from "../components/view/ProductsFilter";
import { ContentfulContext } from "../context/ContentfulContext";
import { ProductsContext } from "../context/ProductsContext";
import { UserContext } from "../context/UserContext";
import { Products } from "../models/schema";

const PAGE_SIZE = 12;

const ProductsPage = () => {
  const { user } = useContext(UserContext);
  const { content } = useContext(ContentfulContext);

  const { title, description, noItems } = content?.products as Products;

  const [filterParams, setFilterParams] = useState<FilterParams>({});
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [showItems, setShowItems] = useState<boolean>(false);
  const [filterInProgress, setFilterInProgress] = useState<boolean>(false);

  const { products, loading, reloadProducts } = useContext(ProductsContext);

  // When the products page is loaded, reload the products
  useEffect(() => {
    reloadProducts();
  }, []);

  const filteredProducts = useMemo(() => {
    setFilterInProgress(true);

    const filtered = products?.filter((product) => {
      const {
        type,
        discount,
        discontinued,
        preparing,
        new: isNew,
        size,
        thickness,
        design,
        surface,
        technical,
        color,
        name,
        price,
        quantity
      } = filterParams;

      const condition =
        (!type?.length || product.types?.some((t) => type.includes(t))) &&
        ((!discount && !preparing && !isNew && !discontinued) ||
          (discount && product.editions?.includes(StockEditionsEnum.Discount)) ||
          (discontinued && product.editions?.includes(StockEditionsEnum.Discontinued)) ||
          (preparing && product.editions?.includes(StockEditionsEnum.Preparing)) ||
          (isNew && product.editions?.includes(StockEditionsEnum.New))) &&
        (!size?.length || (product.size && size.includes(product.size))) &&
        (!thickness?.length || (product.thickness && thickness.includes(product.thickness))) &&
        (!design?.length || product.designs?.some((d) => design.includes(d))) &&
        (!surface?.length || product.surfaces?.some((s) => surface.includes(s))) &&
        (!technical?.length || product.technicalParameters?.some((t) => technical.includes(t))) &&
        (!color?.length || product.colors?.some((c) => color.includes(c))) &&
        (!name?.length || product.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase())) &&
        (!price || (!isNaN(Number(product.price)) && (product.price as number) <= price)) &&
        (!quantity ||
          (!isNaN(Number(product.packagesOnStock)) &&
            !isNaN(Number(product.amountPerPackage)) &&
            (product.packagesOnStock ?? 0) * (product.amountPerPackage ?? 0) >= quantity));

      return condition;
    });

    setFilterInProgress(false);
    return filtered;
  }, [products, filterParams]);

  useEffect(() => {
    if (!filteredProducts?.length) {
      setTotalPages(1);
      setShowItems(false);
      return;
    }

    setTotalPages(Math.ceil(filteredProducts?.length / PAGE_SIZE));
    setShowItems(true);
  }, [filteredProducts]);

  useEffect(() => {
    setHasMore(page < totalPages);
  }, [page, totalPages]);

  const nextPage = () => {
    setPage((prev) => {
      const nextPage = prev + 1;
      if (nextPage > totalPages) return prev;

      return nextPage;
    });
  };

  return (
    <Box>
      <Typography variant="h1" fontWeight="bold" textAlign="center" mb={{ xs: 4, md: 8 }}>
        {title}
      </Typography>

      <RenderContent content={description?.json} sx={{ mb: 8, textAlign: "center" }} />

      <ProductsFilter
        onFilterChange={(data) => {
          setFilterParams(data);
        }}
      />

      {(loading || filterInProgress) && <LoadingSpinner />}

      {!(loading || filterInProgress) && showItems && (
        <InfiniteScroll
          pageStart={1}
          loadMore={nextPage}
          hasMore={hasMore}
          loader={<LoadingSpinner key="loading-spinner" />}>
          <Box key="wrapper">
            <Grid container spacing={6}>
              {filteredProducts?.slice(0, page * PAGE_SIZE).map((product) => (
                <Grid
                  key={product.ean}
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  xl={3}
                  sx={{ display: "flex", justifyContent: "center" }}>
                  <ProductCard product={product} />
                </Grid>
              ))}
            </Grid>
            {filteredProducts?.length > 0 && (
              <Box
                key="scroll-up-button"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mt: 5
                }}>
                <Button
                  variant={ButtonVariant.secondary}
                  onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                  endIcon={<ArrowUpwardIcon />}
                />
              </Box>
            )}
          </Box>
        </InfiniteScroll>
      )}

      {!(loading || filterInProgress) && !showItems && (
        <Alert severity="info" key="no-items">
          <RenderContent content={noItems?.json} />
        </Alert>
      )}

      {!user && (
        <Box sx={{ mt: 6 }} key="become-partner">
          <BecomePartnerBanner />
        </Box>
      )}
    </Box>
  );
};

export default ProductsPage;
