/* tslint:disable */
/* eslint-disable */
/**
 * EcoCerram API
 * EcoCerram API generated from BE
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  Stock,
  StockFilter,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    StockFromJSON,
    StockToJSON,
    StockFilterFromJSON,
    StockFilterToJSON,
} from '../models';

export interface GetAllStockRequest {
    stockFilter: StockFilter;
}

export interface GetStockByEanRequest {
    ean: string;
}

export interface GetStockImageRequest {
    imageName: string;
}

/**
 * 
 */
export class StockControllerApi extends runtime.BaseAPI {

    /**
     */
    async getAllStockRaw(requestParameters: GetAllStockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Stock>>> {
        if (requestParameters.stockFilter === null || requestParameters.stockFilter === undefined) {
            throw new runtime.RequiredError('stockFilter','Required parameter requestParameters.stockFilter was null or undefined when calling getAllStock.');
        }

        const queryParameters: any = {};

        if (requestParameters.stockFilter !== undefined) {
            queryParameters['stockFilter'] = requestParameters.stockFilter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stock`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StockFromJSON));
    }

    /**
     */
    async getAllStock(requestParameters: GetAllStockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Stock>> {
        const response = await this.getAllStockRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStockByEanRaw(requestParameters: GetStockByEanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Stock>> {
        if (requestParameters.ean === null || requestParameters.ean === undefined) {
            throw new runtime.RequiredError('ean','Required parameter requestParameters.ean was null or undefined when calling getStockByEan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stock/{ean}`.replace(`{${"ean"}}`, encodeURIComponent(String(requestParameters.ean))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockFromJSON(jsonValue));
    }

    /**
     */
    async getStockByEan(requestParameters: GetStockByEanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Stock> {
        const response = await this.getStockByEanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStockImageRaw(requestParameters: GetStockImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.imageName === null || requestParameters.imageName === undefined) {
            throw new runtime.RequiredError('imageName','Required parameter requestParameters.imageName was null or undefined when calling getStockImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stock/image/{imageName}`.replace(`{${"imageName"}}`, encodeURIComponent(String(requestParameters.imageName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getStockImage(requestParameters: GetStockImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getStockImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
