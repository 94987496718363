/* tslint:disable */
/* eslint-disable */
/**
 * EcoCerram API
 * EcoCerram API generated from BE
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BecomePartnerForm
 */
export interface BecomePartnerForm {
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    companyType?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    ico?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    dic?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    icDph?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    recaptchaToken?: string;
    /**
     * 
     * @type {string}
     * @memberof BecomePartnerForm
     */
    locale?: BecomePartnerFormLocaleEnum;
}


/**
 * @export
 */
export const BecomePartnerFormLocaleEnum = {
    Sk: 'SK',
    En: 'EN'
} as const;
export type BecomePartnerFormLocaleEnum = typeof BecomePartnerFormLocaleEnum[keyof typeof BecomePartnerFormLocaleEnum];


/**
 * Check if a given object implements the BecomePartnerForm interface.
 */
export function instanceOfBecomePartnerForm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BecomePartnerFormFromJSON(json: any): BecomePartnerForm {
    return BecomePartnerFormFromJSONTyped(json, false);
}

export function BecomePartnerFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): BecomePartnerForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyType': !exists(json, 'companyType') ? undefined : json['companyType'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'ico': !exists(json, 'ico') ? undefined : json['ico'],
        'dic': !exists(json, 'dic') ? undefined : json['dic'],
        'icDph': !exists(json, 'icDph') ? undefined : json['icDph'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'recaptchaToken': !exists(json, 'recaptchaToken') ? undefined : json['recaptchaToken'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
    };
}

export function BecomePartnerFormToJSON(value?: BecomePartnerForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'companyType': value.companyType,
        'description': value.description,
        'email': value.email,
        'phone': value.phone,
        'ico': value.ico,
        'dic': value.dic,
        'icDph': value.icDph,
        'street': value.street,
        'city': value.city,
        'zipCode': value.zipCode,
        'country': value.country,
        'recaptchaToken': value.recaptchaToken,
        'locale': value.locale,
    };
}

