import { Box, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { Anchors, routes } from "../../constants/routes";
import { ContentfulContext } from "../../context/ContentfulContext";
import { About as AboutModel, AboutCategory, Maybe } from "../../models/schema";
import { getButton } from "../../querries/getters";
import { ButtonSize, ButtonVariant } from "../utils/Button";
import NavButton from "../utils/NavButton";
import RenderContent from "../utils/RenderContent";
import ImportantPartners from "./ImportantPartners";

const AboutUs = () => {
  const { content } = useContext(ContentfulContext);
  const buttons = content?.buttonsCollection?.items;

  const { categoriesCollection, title } = content?.home?.about as AboutModel;

  return (
    <Box
      id={Anchors.aboutUs}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > :not(template) ~ :not(template)": {
          mt: 2
        }
      }}>
      <Typography variant="h4" fontWeight="bold" mb={{ xs: 6, md: 8 }}>
        {title}
      </Typography>
      {categoriesCollection?.items?.map((category: Maybe<AboutCategory>, index: number) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: index % 2 === 0 ? "row" : "row-reverse"
            }
          }}>
          <Box
            sx={{
              width: "100%",
              px: { xs: 0, md: 5 },
              py: { xs: 3, md: 5 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}>
            <Stack
              direction="row"
              spacing={3}
              sx={{
                alignItems: "center",
                justifyContent: { xs: "center", md: "start" },
                mb: 5
              }}>
              <Typography
                variant="h1"
                fontWeight="bold"
                sx={{
                  background: "linear-gradient(180deg, #2F2A31 0%, rgba(47, 42, 49, 0.27) 100%)",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: "bold"
                }}>
                {category?.amount}
              </Typography>
              <RenderContent content={category?.title?.json} />
            </Stack>

            <RenderContent content={category?.description?.json} />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}>
            {category?.image && (
              <Box
                component={"img"}
                src={category?.image.url as string}
                alt={category?.image.title as string}
                sx={(theme) => ({
                  borderRadius: theme.spacing(4),
                  boxShadow: "5px 5px 4px 0px rgba(0, 0, 0, 0.25)",
                  width: { xs: "100%", md: "80%" }
                })}
              />
            )}
          </Box>
        </Box>
      ))}
      <NavButton
        route={routes.partner}
        label={getButton(buttons, "becomePartner")}
        variant={ButtonVariant.primary}
        size={ButtonSize.full}
        sx={{
          width: "18em",
          my: 10
        }}
      />

      <ImportantPartners />
    </Box>
  );
};

export default AboutUs;
