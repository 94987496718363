/* tslint:disable */
/* eslint-disable */
/**
 * EcoCerram API
 * EcoCerram API generated from BE
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    ico?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    locale?: UserDtoLocaleEnum;
}


/**
 * @export
 */
export const UserDtoLocaleEnum = {
    Sk: 'SK',
    En: 'EN'
} as const;
export type UserDtoLocaleEnum = typeof UserDtoLocaleEnum[keyof typeof UserDtoLocaleEnum];


/**
 * Check if a given object implements the UserDto interface.
 */
export function instanceOfUserDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ico': !exists(json, 'ico') ? undefined : json['ico'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ico': value.ico,
        'email': value.email,
        'companyName': value.companyName,
        'locale': value.locale,
    };
}

