import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ContactForm, ErrorResponse } from "../api";
import Button, { ButtonVariant } from "../components/utils/Button";
import ButtonContainer from "../components/utils/ButtonContainer";
import CookieConsent from "../components/utils/CookieConsent";
import GoogleMap from "../components/utils/GoogleMap";
import Input from "../components/utils/Input";
import NavButton from "../components/utils/NavButton";
import RenderContent from "../components/utils/RenderContent";
import Showroom from "../components/view/Showroom";
import Warehouse from "../components/view/Warehouse";
import { ContentfulContext } from "../context/ContentfulContext";
import { ErrorContext } from "../context/ErrorContext";
import { RecaptchaContext } from "../context/RecaptchaContext";
import { Contact, Language } from "../models/schema";
import { contactApi } from "../querries/api";
import { getButton, getValidationMsg } from "../querries/getters";
import { EMAIL_REGEX } from "../utils/utilities";
import OperationResult, { OperationResultType } from "./auth/OperationResult";

const ContactPage = () => {
  const { recaptchaRef, showBadge, hideBadge, neccessaryCookiesAllowed } =
    useContext(RecaptchaContext);
  const { content } = useContext(ContentfulContext);
  const {
    title,
    description,
    email,
    contactData,
    billingData,
    bankData,
    contactUs,
    name,
    question,
    consentPersonalData,
    successMessage,
    errorMessage,
    emailValue,
    phoneValue,
    billingDataValue,
    bankDataValue,
    addressTitle,
    addressDescription,
    addressUrl
  } = content?.contact as Contact;
  const { validationErrorMessage } = content as Language;

  const buttons = content?.buttonsCollection?.items;
  const validations = content?.validationsCollection?.items;

  const [result, setResult] = useState<OperationResultType>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>();
  const { setError } = useContext(ErrorContext);

  type Form = {
    name: string;
    email: string;
    question: string;
    consentPersonalData: string;
  };

  const controls: Form = {
    name: "name",
    email: "email",
    question: "question",
    consentPersonalData: "consentPersonalData"
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data: unknown) => {
    if (!neccessaryCookiesAllowed) {
      return;
    }

    const token = recaptchaRef.current ? await recaptchaRef.current?.executeAsync() : null;

    if (!token) {
      setResult("ERROR");
      return;
    }

    setLoading(true);

    const { name, email, question } = data as Form;

    const contactForm: ContactForm = {
      name,
      email,
      question,
      recaptchaToken: token
    };

    contactApi
      .contact({ contactForm })
      .then(() => {
        setResult("SUCCESS");
        setLoading(false);
        recaptchaRef.current?.reset();
      })
      .catch((error) => {
        setLoading(false);
        recaptchaRef.current?.reset();

        if (error?.response?.status === 401) {
          setResult("ERROR");
          return;
        }

        error.response.json().then((json: ErrorResponse) => {
          console.error(json);

          if (json?.errorCode) {
            setErrorCode(json?.errorCode);
          }

          // Bussiness logic errors are 4xx
          if (Math.floor(error?.response?.status / 100) === 4) {
            setResult("ERROR");
            return;
          }

          // Otherwise it's an unexpected server error
          setError(error);
        });
      });
  };

  const resetForm = () => {
    reset();
    setResult(null);
  };

  useEffect(() => {
    showBadge();
    return () => {
      hideBadge();
    };
  }, [showBadge, hideBadge]);

  return (
    <Box>
      <Typography variant="h1" fontWeight="bold" textAlign="center" mb={{ xs: 4, md: 8 }}>
        {title}
      </Typography>
      <RenderContent content={description?.json} sx={{ mb: 8, textAlign: "center" }} />

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
          mb: 8
        }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              fontWeight="bold"
              textAlign="left"
              mb={{ xs: 4, md: 6 }}
              px={{ xs: 0, md: 3 }}>
              {contactData}
            </Typography>
            <Grid container spacing={5} px={{ xs: 0, md: 3 }}>
              <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                <NavButton
                  external
                  variant={ButtonVariant.secondary}
                  route={`mailto:${emailValue}`}
                  startIcon={<MailOutlineIcon />}
                  sx={{ mr: 5 }}
                />
                <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                  {emailValue}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                <NavButton
                  external
                  variant={ButtonVariant.secondary}
                  route={`tel:${phoneValue}`}
                  startIcon={<LocalPhoneOutlinedIcon />}
                  sx={{ mr: 5 }}
                />
                <Typography variant="body2">{phoneValue}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5} px={{ xs: 0, md: 3 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" fontWeight="bold" textAlign="left" my={{ xs: 4, md: 6 }}>
                  {billingData}
                </Typography>
                <RenderContent content={billingDataValue?.json} />
              </Grid>
              <Grid item xs={12} md={6} pr={{ xs: 0, md: 3 }}>
                <Typography variant="h4" fontWeight="bold" textAlign="left" my={{ xs: 4, md: 6 }}>
                  {bankData}
                </Typography>
                <RenderContent content={bankDataValue?.json} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {!result && (
        <Paper
          component={"form"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4
          }}>
          <Typography variant="h4" fontWeight="bold" textAlign="center" my={{ xs: 4, md: 6 }}>
            {contactUs}
          </Typography>

          <Input
            required
            id={controls.name}
            label={name as string}
            error={!!errors.name}
            helperText={errors.name && (errors.name.message as string)}
            name={controls.name}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Input
            required
            type="email"
            id={controls.email}
            label={email as string}
            error={!!errors.email}
            helperText={errors.email && (errors.email.message as string)}
            name={controls.email}
            rules={{
              required: getValidationMsg(validations, "required"),
              pattern: {
                value: EMAIL_REGEX,
                message: getValidationMsg(validations, "format")
              }
            }}
            control={control}
          />

          <Input
            required
            multiline
            rows={6}
            id={controls.question}
            label={question as string}
            error={!!errors.question}
            helperText={errors.question && (errors.question.message as string)}
            name={controls.question}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Controller
            name={controls.consentPersonalData}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
            render={({ field }) => (
              <FormControl
                required
                sx={(theme) => ({
                  width: "100%",
                  maxWidth: theme.spacing(100),
                  color: errors.consentPersonalData
                    ? theme.palette.error.main
                    : theme.palette.text.primary
                })}
                error={!!errors.consentPersonalData}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={(theme) => ({
                        color: errors.consentPersonalData
                          ? theme.palette.error.main
                          : theme.palette.text.primary
                      })}
                      {...field}
                    />
                  }
                  label={consentPersonalData as string}
                  color={errors.consentPersonalData ? "error" : "primary"}
                />
                <FormHelperText color="error">
                  {errors.consentPersonalData && (errors.consentPersonalData.message as string)}
                </FormHelperText>
              </FormControl>
            )}
          />

          {!neccessaryCookiesAllowed && (
            <Box
              sx={(theme) => ({
                mt: 5,
                maxWidth: theme.spacing(100),
                display: "flex",
                justifyContent: "center"
              })}>
              <CookieConsent type="alert" />
            </Box>
          )}

          <ButtonContainer sx={{ mt: 5 }}>
            <Button
              disabled={!neccessaryCookiesAllowed}
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              label={getButton(buttons, "send")}
              variant={ButtonVariant.primary}
            />
          </ButtonContainer>
        </Paper>
      )}

      {result && (
        <OperationResult
          result={result}
          successTitle={successMessage as string}
          errorTitle={
            errorCode
              ? `${validationErrorMessage} ${getValidationMsg(validations, errorCode)}`
              : (errorMessage as string)
          }>
          {result === "SUCCESS" && (
            <Button
              onClick={resetForm}
              label={getButton(buttons, "close")}
              variant={ButtonVariant.primary}
            />
          )}

          {result === "ERROR" && (
            <Button
              onClick={() => setResult(null)}
              label={getButton(buttons, "tryAgain")}
              variant={ButtonVariant.primary}
            />
          )}
        </OperationResult>
      )}

      <Box mt={8}>
        <Showroom />
      </Box>
      <Box mt={8}>
        <Warehouse />
      </Box>

      <Typography variant="h4" fontWeight="bold" textAlign="center" mt={8} mb={{ xs: 4, md: 6 }}>
        {addressTitle}
      </Typography>
      <RenderContent content={addressDescription?.json} sx={{ textAlign: "center", mb: 6 }} />

      <GoogleMap url={addressUrl as string} />
    </Box>
  );
};

export default ContactPage;
