import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import { Controller, useForm } from "react-hook-form";
import { BecomePartnerForm, ErrorResponse } from "../api";
import Button, { ButtonVariant } from "../components/utils/Button";
import ButtonContainer from "../components/utils/ButtonContainer";
import CookieConsent from "../components/utils/CookieConsent";
import Input from "../components/utils/Input";
import NavButton from "../components/utils/NavButton";
import RenderContent from "../components/utils/RenderContent";
import { DiscountInfo } from "../components/view/DiscountInfo";
import ImportantPartners from "../components/view/ImportantPartners";
import { countryOptions, defaultCountry } from "../constants/countries";
import { routes } from "../constants/routes";
import { ContentfulContext } from "../context/ContentfulContext";
import { ErrorContext } from "../context/ErrorContext";
import { LanguageContext } from "../context/LanguageContext";
import { RecaptchaContext } from "../context/RecaptchaContext";
import { UserContext } from "../context/UserContext";
import { Language, PartnerForm } from "../models/schema";
import { authApi } from "../querries/api";
import { getButton, getValidationMsg } from "../querries/getters";
import { EMAIL_REGEX } from "../utils/utilities";
import OperationResult, { OperationResultType } from "./auth/OperationResult";

const PartnerPage = () => {
  const { user } = useContext(UserContext);
  const { setError } = useContext(ErrorContext);
  const { content } = useContext(ContentfulContext);
  const { selectedLanguage } = useContext(LanguageContext);
  const { recaptchaRef, showBadge, hideBadge, neccessaryCookiesAllowed } =
    useContext(RecaptchaContext);
  const {
    title,
    titleUser,
    description,
    descriptionUser,
    generalData,
    name,
    field,
    fieldOptions,
    reason,
    contactData,
    email,
    phone,
    billingData,
    ico,
    dic,
    icdph,
    street,
    city,
    zip,
    country,
    consentPersonalData,
    successMessage,
    errorMessage,
    discountTitle,
    discountDescription
  } = content?.becomePartner as PartnerForm;
  const { validationErrorMessage } = content as Language;

  const buttons = content?.buttonsCollection?.items;
  const validations = content?.validationsCollection?.items;

  const [result, setResult] = useState<OperationResultType>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>();

  type Form = {
    // General data
    name: string;
    field: string;
    reason: string;
    // Contact data
    email: string;
    phone: string;
    // Billing data
    ico: string;
    dic: string;
    icdph: string;
    street: string;
    city: string;
    zip: string;
    country: string;
    consentPersonalData: string;
  };

  const controls: Form = {
    name: "name",
    field: "field",
    reason: "reason",
    email: "email",
    phone: "phone",
    ico: "ico",
    dic: "dic",
    icdph: "icdph",
    street: "street",
    city: "city",
    zip: "zip",
    country: "country",
    consentPersonalData: "consentPersonalData"
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data: unknown) => {
    if (!neccessaryCookiesAllowed) {
      return;
    }

    const token = recaptchaRef.current ? await recaptchaRef.current?.executeAsync() : null;

    if (!token) {
      setResult("ERROR");
      return;
    }

    setLoading(true);

    const { name, field, reason, email, phone, ico, dic, icdph, street, city, zip, country } =
      data as Form;

    const registrationRequest: BecomePartnerForm = {
      companyName: name,
      companyType: field,
      description: reason,
      email,
      phone,
      ico,
      dic,
      icDph: icdph,
      street,
      city,
      zipCode: zip,
      country: countryOptions.find((opt) => opt.value === country)?.label,
      locale: selectedLanguage === "sk" ? "SK" : "EN",
      recaptchaToken: token
    };

    authApi
      .registration({ becomePartnerForm: registrationRequest })
      .then(() => {
        setResult("SUCCESS");
        setLoading(false);
        recaptchaRef.current?.reset();
      })
      .catch((error) => {
        setLoading(false);
        recaptchaRef.current?.reset();

        if (error?.response?.status === 401) {
          setResult("ERROR");
          return;
        }

        error.response.json().then((json: ErrorResponse) => {
          console.error(json);

          if (json?.errorCode) {
            setErrorCode(json?.errorCode);
          }

          // Bussiness logic errors are 4xx
          if (Math.floor(error?.response?.status / 100) === 4) {
            setResult("ERROR");
            return;
          }

          // Otherwise it's an unexpected server error
          setError(error);
        });
      });
  };

  const resetForm = () => {
    setResult(null);
  };

  useEffect(() => {
    showBadge();
    return () => {
      hideBadge();
    };
  }, [showBadge, hideBadge]);

  useEffect(() => {
    setValue(controls.country, defaultCountry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  return (
    <Box>
      <Box mb={{ xs: 4, md: 8 }}>
        <Typography variant="h1" fontWeight="bold" textAlign="center" mb={{ xs: 4, md: 8 }}>
          {discountTitle}
        </Typography>
        <RenderContent content={discountDescription?.json} sx={{ mb: 8, textAlign: "center" }} />

        <DiscountInfo />
      </Box>

      <Typography variant="h2" fontWeight="bold" textAlign="center" my={{ xs: 4, md: 6 }}>
        {user ? titleUser : title}
      </Typography>

      <RenderContent
        content={user ? descriptionUser?.json : description?.json}
        sx={{ mb: 8, textAlign: "center" }}
      />

      {!result && (
        <Paper
          component={"form"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4
          }}>
          <Typography variant="h4" fontWeight="bold" textAlign="center" mb={{ xs: 4, md: 6 }}>
            {generalData}
          </Typography>

          <Input
            required
            id={controls.name}
            label={name as string}
            error={!!errors.name}
            helperText={errors.name && (errors.name.message as string)}
            name={controls.name}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Box
            sx={(theme) => ({
              width: { xs: "100%", md: theme.spacing(100) },
              maxWidth: theme.spacing(100)
            })}
            mb={3}>
            <Typography
              variant="body2"
              mb={1}
              sx={(theme) => ({
                color: errors.field ? theme.palette.error.main : theme.palette.text.primary
              })}>
              {field as string}*
            </Typography>

            <Controller
              name={controls.field}
              rules={{
                required: getValidationMsg(validations, "required")
              }}
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: "100%" }} error={!!errors.field}>
                  <Select required id={controls.field} variant="outlined" {...field}>
                    {fieldOptions?.map((option) => (
                      <MenuItem key={option} value={option as string}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText color="error">
                    {errors.field && (errors.field.message as string)}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Box>

          <Input
            required
            multiline
            rows={6}
            id={controls.reason}
            label={reason as string}
            error={!!errors.reason}
            helperText={errors.reason && (errors.reason.message as string)}
            name={controls.reason}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Typography variant="h4" fontWeight="bold" textAlign="center" my={{ xs: 4, md: 6 }}>
            {contactData}
          </Typography>

          <Input
            required
            type="email"
            id={controls.email}
            label={email as string}
            error={!!errors.email}
            helperText={errors.email && (errors.email.message as string)}
            name={controls.email}
            rules={{
              required: getValidationMsg(validations, "required"),
              pattern: {
                value: EMAIL_REGEX,
                message: getValidationMsg(validations, "format")
              }
            }}
            control={control}
          />

          <Input
            required
            id={controls.phone}
            label={phone as string}
            error={!!errors.phone}
            helperText={errors.phone && (errors.phone.message as string)}
            name={controls.phone}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Typography variant="h4" fontWeight="bold" textAlign="center" my={{ xs: 4, md: 6 }}>
            {billingData}
          </Typography>

          <Input
            required
            id={controls.ico}
            label={ico as string}
            error={!!errors.ico}
            helperText={errors.ico && (errors.ico.message as string)}
            name={controls.ico}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Input
            required
            id={controls.dic}
            label={dic as string}
            error={!!errors.dic}
            helperText={errors.dic && (errors.dic.message as string)}
            name={controls.dic}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Input
            required
            id={controls.icdph}
            label={icdph as string}
            sx={{ mb: 10 }}
            error={!!errors.icdph}
            helperText={errors.icdph && (errors.icdph.message as string)}
            name={controls.icdph}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Input
            required
            id={controls.ico}
            label={street as string}
            error={!!errors.street}
            helperText={errors.street && (errors.street.message as string)}
            name={controls.street}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Input
            required
            id={controls.city}
            label={city as string}
            error={!!errors.city}
            helperText={errors.city && (errors.city.message as string)}
            name={controls.city}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Input
            required
            id={controls.zip}
            label={zip as string}
            error={!!errors.zip}
            helperText={errors.zip && (errors.zip.message as string)}
            name={controls.zip}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <Box
            sx={(theme) => ({
              width: { xs: "100%", md: theme.spacing(100) },
              maxWidth: theme.spacing(100)
            })}
            mb={3}>
            <Typography
              variant="body2"
              mb={1}
              sx={(theme) => ({
                color: errors.country ? theme.palette.error.main : theme.palette.text.primary
              })}>
              {country as string}*
            </Typography>

            <Controller
              name={controls.country}
              rules={{
                required: getValidationMsg(validations, "required")
              }}
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: "100%" }} error={!!errors.country}>
                  <Select
                    required
                    defaultValue={defaultCountry}
                    renderValue={(value) => (
                      <Stack direction="row" spacing={1}>
                        <CircleFlag countryCode={value} height="20" />
                        <Typography>
                          {countryOptions.find((opt) => opt.value === value)?.label}
                        </Typography>
                      </Stack>
                    )}
                    id={controls.country}
                    variant="outlined"
                    {...field}>
                    {countryOptions?.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: "90vw"
                        }}>
                        <Stack direction="row" spacing={1}>
                          <CircleFlag countryCode={option.value} height="20" />
                          <Typography>{option.label}</Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText color="error">
                    {errors.field && (errors.field.message as string)}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Box>

          <Controller
            name={controls.consentPersonalData}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
            render={({ field }) => (
              <FormControl
                required
                sx={(theme) => ({
                  width: { xs: "100%", md: theme.spacing(100) },
                  maxWidth: theme.spacing(100),
                  color: errors.consentPersonalData
                    ? theme.palette.error.main
                    : theme.palette.text.primary
                })}
                error={!!errors.consentPersonalData}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={(theme) => ({
                        color: errors.consentPersonalData
                          ? theme.palette.error.main
                          : theme.palette.text.primary
                      })}
                      {...field}
                    />
                  }
                  label={consentPersonalData as string}
                  color={errors.consentPersonalData ? "error" : "primary"}
                />
                <FormHelperText color="error">
                  {errors.consentPersonalData && (errors.consentPersonalData.message as string)}
                </FormHelperText>
              </FormControl>
            )}
          />

          {!neccessaryCookiesAllowed && (
            <Box
              sx={(theme) => ({
                mt: 5,
                maxWidth: theme.spacing(100),
                display: "flex",
                justifyContent: "center"
              })}>
              <CookieConsent type="alert" />
            </Box>
          )}

          <ButtonContainer sx={{ mt: 5 }}>
            <Button
              disabled={!neccessaryCookiesAllowed}
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              label={getButton(buttons, "send")}
              variant={ButtonVariant.primary}
            />
          </ButtonContainer>

          <Box mt={8} width={"100%"}>
            <ImportantPartners wrapper={false} />
          </Box>
        </Paper>
      )}

      {result && (
        <OperationResult
          result={result}
          successTitle={successMessage as string}
          errorTitle={
            errorCode
              ? `${validationErrorMessage} ${getValidationMsg(validations, errorCode)}`
              : (errorMessage as string)
          }>
          {result === "SUCCESS" && (
            <NavButton
              route={routes.home}
              label={getButton(buttons, "home")}
              variant={ButtonVariant.primary}
            />
          )}

          {result === "ERROR" && (
            <ButtonContainer sx={{ mt: 5 }}>
              <NavButton
                route={routes.contact}
                label={getButton(buttons, "contact")}
                variant={ButtonVariant.secondary}
              />
              <Button
                onClick={resetForm}
                label={getButton(buttons, "tryAgain")}
                variant={ButtonVariant.primary}
              />
            </ButtonContainer>
          )}
        </OperationResult>
      )}
    </Box>
  );
};

export default PartnerPage;
