/* tslint:disable */
/* eslint-disable */
/**
 * EcoCerram API
 * EcoCerram API generated from BE
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BecomePartnerForm,
  ChangePasswortDto,
  ErrorResponse,
  MemoryStats,
  UserDto,
} from '../models';
import {
    BecomePartnerFormFromJSON,
    BecomePartnerFormToJSON,
    ChangePasswortDtoFromJSON,
    ChangePasswortDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MemoryStatsFromJSON,
    MemoryStatsToJSON,
    UserDtoFromJSON,
    UserDtoToJSON,
} from '../models';

export interface ChangePasswordRequest {
    changePasswortDto: ChangePasswortDto;
}

export interface ForgotPasswordRequest {
    username: string;
}

export interface RegistrationRequest {
    becomePartnerForm: BecomePartnerForm;
}

export interface UpdateLocaleRequest {
    value: UpdateLocaleValueEnum;
}

/**
 * 
 */
export class AuthControllerApi extends runtime.BaseAPI {

    /**
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changePasswortDto === null || requestParameters.changePasswortDto === undefined) {
            throw new runtime.RequiredError('changePasswortDto','Required parameter requestParameters.changePasswortDto was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/change-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswortDtoToJSON(requestParameters.changePasswortDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async forgotPasswordRaw(requestParameters: ForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling forgotPassword.');
        }

        const queryParameters: any = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/forgot-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async forgotPassword(requestParameters: ForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getMemoryStatisticsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemoryStats>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/memory-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemoryStatsFromJSON(jsonValue));
    }

    /**
     */
    async getMemoryStatistics(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemoryStats> {
        const response = await this.getMemoryStatisticsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async loginRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async login(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.loginRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutRaw(initOverrides);
    }

    /**
     */
    async registrationRaw(requestParameters: RegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.becomePartnerForm === null || requestParameters.becomePartnerForm === undefined) {
            throw new runtime.RequiredError('becomePartnerForm','Required parameter requestParameters.becomePartnerForm was null or undefined when calling registration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/registration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BecomePartnerFormToJSON(requestParameters.becomePartnerForm),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async registration(requestParameters: RegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registrationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateLocaleRaw(requestParameters: UpdateLocaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters.value === null || requestParameters.value === undefined) {
            throw new runtime.RequiredError('value','Required parameter requestParameters.value was null or undefined when calling updateLocale.');
        }

        const queryParameters: any = {};

        if (requestParameters.value !== undefined) {
            queryParameters['value'] = requestParameters.value;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/locale`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateLocale(requestParameters: UpdateLocaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.updateLocaleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const UpdateLocaleValueEnum = {
    Sk: 'SK',
    En: 'EN'
} as const;
export type UpdateLocaleValueEnum = typeof UpdateLocaleValueEnum[keyof typeof UpdateLocaleValueEnum];
